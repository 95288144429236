import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BackofficeRouteLocations } from './common/routing/route-locations';
import { LoggedInRouteGuard } from './common/routing/route-guards/logged-in.guard';
import {
  FeaturePermissionsRouteGuard,
  FeaturePermissionsRouteGuardData,
} from './common/routing/route-guards/feature-permissions.guard';
import { FeaturePermission } from '../../../common/model/feature-permissions.enum';

const routes: Routes = [
  {
    path: BackofficeRouteLocations.Login.relativeUrl,
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: BackofficeRouteLocations.Dashboard,
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [LoggedInRouteGuard, FeaturePermissionsRouteGuard],
    data: <FeaturePermissionsRouteGuardData>{
      requiredFeaturePermissions: [FeaturePermission.Backoffice],
    },
  },
  {
    path: '**',
    redirectTo: BackofficeRouteLocations.Login.relativeUrl,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
